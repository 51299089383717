import { defineComponent, ref } from 'vue';
import DayLayers from './HomeView/DayLayers.vue';
import SunsetLayers from './HomeView/SunsetLayers.vue';
import NightLayers from './HomeView/NightLayers.vue';
import GenesisSection from './HomeView/GenesisSection.vue';
import ConceptSection from './HomeView/ConceptSection.vue';
import StorySection from './HomeView/StorySection.vue';
import GuardiansSection from './HomeView/GuardiansSection.vue';
import TeamSection from './HomeView/TeamSection.vue';
import FaqFooter from './HomeView/FaqFooter.vue';
import LoadingMask from '@/components/LoadingMask.vue';
import SoundSelect from '@/components/SoundSelect.vue';
import luxy from 'luxy.js';
export default defineComponent({
  name: 'HomeView',
  components: {
    LoadingMask,
    SoundSelect,
    DayLayers,
    SunsetLayers,
    NightLayers,
    GenesisSection,
    ConceptSection,
    StorySection,
    GuardiansSection,
    TeamSection,
    FaqFooter
  },
  data() {
    return {
      layer: this.$route.query.layer || 'day',
      windowWidth: window?.innerWidth > 0 ? window.innerWidth : 0,
      isPageReady: false,
      totalLoadingAssets: Infinity,
      remainingLoadingAssets: Infinity,
      hasSelectedSound: false,
      isMusicPlaying: false,
      mutationObserver: null,
      luxy: null,
      scrollPosition: 0,
      activeMenu: {
        'concept-section': false,
        'genesis-section': false,
        'story-section': false,
        'guardians-section': false,
        'team-section': false
      }
    };
  },
  computed: {
    isSmallDevice() {
      return this.windowWidth <= 1200;
    },
    remainingAssetsProgress() {
      return this.remainingLoadingAssets / this.totalLoadingAssets * 100;
    },
    isTopOfPage() {
      return this.scrollPosition <= 50;
    },
    displayBottomFrameDecoration() {
      return window.innerHeight + this.scrollPosition + 550 >= document.body.offsetHeight;
    },
    isConceptMenuActive() {
      return this.activeMenu['concept-section'];
    },
    isGenesisMenuActive() {
      return this.activeMenu['genesis-section'];
    },
    isStoryMenuActive() {
      return this.activeMenu['story-section'];
    },
    isGuardiansMenuActive() {
      return this.activeMenu['guardians-section'];
    },
    isTeamMenuActive() {
      return this.activeMenu['team-section'];
    }
  },
  methods: {
    handleLoadingAssets(value) {
      if (this.totalLoadingAssets === Infinity) {
        this.totalLoadingAssets = value;
      }
      this.remainingLoadingAssets = value;
    },
    togglePlayMusic() {
      if (this.isMusicPlaying) {
        this.isMusicPlaying = false;
        return;
      }
      this.isMusicPlaying = true;
    },
    scrollToElement(element) {
      const selectedElement = document.getElementById(element);
      if (!selectedElement) {
        return;
      }
      const offset = (selectedElement.offsetTop - window.innerHeight / 2 + selectedElement.offsetHeight / 2) * 0.4;
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      });
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    isElementVisible(elementId) {
      const element = document.getElementById(elementId);
      if (!element) {
        return false;
      }
      const rect = element.getBoundingClientRect();
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    },
    isElementOnScreenBorder(elementId) {
      const element = document.getElementById(elementId);
      if (!element) {
        return false;
      }
      const rect = element.getBoundingClientRect();
      const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
      return rect.bottom > 0 && rect.bottom <= rect.height || rect.top - document.documentElement.clientHeight < 100 && rect.top - viewHeight >= -rect.height;
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    }
  },
  mounted() {
    luxy.init();
    window.addEventListener('scroll', this.updateScroll);
    const elements = document.querySelectorAll('.section');
    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const target = mutation.target;
        if (mutation.type === "attributes") {
          target.setAttribute('data-visible', `${this.isElementVisible(target.id)}`);
          target.setAttribute('data-border', `${this.isElementOnScreenBorder(target.id)}`);
          this.activeMenu[target.id] = this.isElementVisible(target.id) && !this.isElementOnScreenBorder(target.id);
        }
      });
    });
    for (const element of elements) {
      this.mutationObserver.observe(element, {
        attributes: true,
        attributeFilter: ['style']
      });
    }
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        this.isPageReady = true;
      }
    };
  },
  unmounted() {
    window.removeEventListener('scroll', this.updateScroll);
    this.mutationObserver?.disconnect();
  },
  setup() {
    const music = ref();
    return {
      music
    };
  },
  watch: {
    layer() {
      this.isPageReady = false;
      this.totalLoadingAssets = Infinity;
      this.remainingLoadingAssets = Infinity;
    },
    isMusicPlaying() {
      if (!this.music) {
        return;
      }
      if (this.isMusicPlaying) {
        this.music.volume = 0.5;
        this.music.play();
        return;
      }
      this.music.pause();
    }
  }
});