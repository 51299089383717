import { defineComponent } from 'vue';
export default defineComponent({
  name: 'LoadingMask',
  props: {
    remainingAssetsProgress: {
      type: Number
    },
    showProgress: {
      type: Boolean
    }
  }
});