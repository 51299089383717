import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CountdownTimer',
  computed: {
    isCountDownOver() {
      return this.countDown.days === '00' && this.countDown.hours === '00' && this.countDown.minutes === '00';
    }
  },
  props: {
    isVerticalScreen: {
      type: Boolean,
      default: false
    },
    countDown: {
      default: {
        days: '00',
        hours: '00',
        minutes: '00'
      }
    },
    close: {
      type: Function,
      default: () => {}
    }
  }
});