import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SuccessComicPage',
  components: {},
  data() {
    return {
      displayThinkAgainComic: false
    };
  },
  props: {
    close: {
      type: Function,
      default: () => {}
    }
  }
});