import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SunsetLayers',
  data() {
    return {
      remainingLoadingImageAssets: 15,
      remainingLoadingVideoAssets: 10,
      processedVideoAssets: new Set()
    };
  },
  props: {
    isSmallDevice: {
      type: Boolean
    }
  },
  computed: {
    remainingLoadingAssets() {
      if (this.isSmallDevice) {
        return this.remainingLoadingImageAssets;
      }
      return this.remainingLoadingVideoAssets + this.remainingLoadingImageAssets;
    }
  },
  methods: {
    loadVideo(event) {
      if (this.processedVideoAssets.has(event.target.currentSrc) || event.target.currentSrc.indexOf('blob:') >= 0 || this.isSmallDevice) {
        return;
      }
      this.processedVideoAssets.add(event.target.currentSrc);
      const req = new XMLHttpRequest();
      req.open('GET', event.target.currentSrc, true);
      req.responseType = 'blob';
      req.onerror = () => {
        this.decreaseLoadingVideoAssets();
      };
      req.onloadend = () => {
        if (req.status === 200) {
          const videoBlob = req.response;
          event.target.src = URL.createObjectURL(videoBlob);
          event.target.play();
        }
        this.decreaseLoadingVideoAssets();
      };
      req.send();
    },
    decreaseLoadingImageAssets() {
      if (this.remainingLoadingImageAssets <= 0) {
        return;
      }
      this.remainingLoadingImageAssets--;
    },
    decreaseLoadingVideoAssets() {
      if (this.remainingLoadingVideoAssets <= 0) {
        return;
      }
      this.remainingLoadingVideoAssets--;
    }
  },
  mounted() {
    this.$emit('remainingLoadingAssets', this.remainingLoadingAssets);
  },
  watch: {
    remainingLoadingAssets() {
      this.$emit('remainingLoadingAssets', this.remainingLoadingAssets);
    }
  }
});