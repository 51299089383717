import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SoundSelect',
  props: {
    remainingAssetsProgress: {
      type: Number
    },
    onSelect: {
      type: Function
    }
  }
});