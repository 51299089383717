import { defineComponent } from 'vue';
export default defineComponent({
  name: 'GenesisSection',
  data() {
    return {
      displayedIndex: 1,
      interval: 0
    };
  },
  mounted() {
    this.interval = window.setInterval(() => this.displayedIndex = this.displayedIndex % 21 + 1, 3000);
  },
  unmounted() {
    clearInterval(this.interval);
  }
});