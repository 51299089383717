import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CountdownTimer = _resolveComponent("CountdownTimer");
  const _component_ComicPage = _resolveComponent("ComicPage");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _component_MintPanels = _resolveComponent("MintPanels");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mint-container", {
      vertical: _ctx.isVerticalScreen
    }])
  }, [_withDirectives(_createVNode(_component_CountdownTimer, {
    close: () => _ctx.displayCountdownTimer = false,
    isVerticalScreen: _ctx.isVerticalScreen,
    countDown: _ctx.countDown
  }, null, 8, ["close", "isVerticalScreen", "countDown"]), [[_vShow, _ctx.displayCountdownTimer]]), _createVNode(_component_el_dialog, {
    "model-value": !_ctx.displayCountdownTimer && _ctx.displayComic,
    center: true,
    "align-center": true,
    class: "mint-dialog",
    width: "100%"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ComicPage, {
      close: () => _ctx.displayComic = false
    }, null, 8, ["close"])])]),
    _: 1
  }, 8, ["model-value"]), _withDirectives(_createVNode(_component_MintPanels, {
    countDownTime: _ctx.countDownTime,
    canFetchData: !_ctx.displayCountdownTimer
  }, null, 8, ["countDownTime", "canFetchData"]), [[_vShow, !_ctx.displayCountdownTimer && !_ctx.displayComic]])], 2);
}