import { defineComponent } from 'vue';
import CountdownTimer from '@/views/MintView/CountdownTimer.vue';
import MintPanels from '@/views/MintView/MintPanels.vue';
import ComicPage from '@/views/MintView/ComicPage.vue';
import { connectToRememberedWallets } from '@/utils/web3-onboard';
import { COUNTDOWN_DAY, COUNTDOWN_HOUR, COUNTDOWN_MIN, COUNTDOWN_MONTH, COUNTDOWN_SEC, COUNTDOWN_YEAR } from '@/utils/countdown';
export default defineComponent({
  name: 'MintView',
  components: {
    CountdownTimer,
    MintPanels,
    ComicPage
  },
  data() {
    return {
      displayCountdownTimer: true,
      displayComic: true,
      windowWidth: window?.innerWidth > 0 ? window.innerWidth : 0,
      windowHeight: window?.innerHeight > 0 ? window.innerHeight : 0,
      countDownTime: new Date(Date.UTC(COUNTDOWN_YEAR, COUNTDOWN_MONTH, COUNTDOWN_DAY, COUNTDOWN_HOUR, COUNTDOWN_MIN, COUNTDOWN_SEC)).getTime(),
      countDown: {
        days: '00',
        hours: '00',
        minutes: '00'
      },
      interval: 0
    };
  },
  computed: {
    isSmallDevice() {
      return this.windowWidth <= 1200;
    },
    isVerticalScreen() {
      return this.windowHeight > this.windowWidth;
    }
  },
  methods: {
    updateCountDown() {
      const now = new Date().getTime();
      const distance = this.countDownTime - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24)) < 0 ? 0 : Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)) < 0 ? 0 : Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
      const minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60)) < 0 ? 0 : Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
      this.countDown = {
        days: days < 10 ? `0${days}` : `${days}`,
        hours: hours < 10 ? `0${hours}` : `${hours}`,
        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`
      };
    },
    startCountDown() {
      this.updateCountDown();
      this.interval = window.setInterval(() => {
        this.updateCountDown();
      }, 1000);
    },
    handleScreenResize() {
      this.windowWidth = window?.innerWidth > 0 ? window.innerWidth : 0;
      this.windowHeight = window?.innerHeight > 0 ? window.innerHeight : 0;
    }
  },
  created() {
    window.addEventListener('resize', this.handleScreenResize);
    this.startCountDown();
    connectToRememberedWallets();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleScreenResize);
    clearInterval(this.interval);
  }
});