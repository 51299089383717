import { defineComponent } from 'vue';
export default defineComponent({
  name: 'MintPanels',
  components: {},
  data() {
    return {
      countDownDelay: 259200000,
      countDown: {
        days: '00',
        hours: '00',
        minutes: '00'
      },
      interval: 0
    };
  },
  props: {
    value: {
      type: Number,
      default: 1
    },
    isWalletConnected: {
      type: Boolean,
      default: false
    },
    connectWallet: {
      type: Function,
      default: () => {}
    },
    mint: {
      type: Function,
      default: () => {}
    },
    maxMint: {
      type: Number,
      default: 10
    },
    countDownTime: {
      type: Number,
      default: 0
    },
    totalSlots: {
      type: Number,
      default: 0
    },
    isPhasePaused: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isCountdownTimerOn() {
      if (+this.countDown.hours <= 0 && +this.countDown.minutes <= 0 && +this.countDown.days <= 0) {
        return false;
      }
      return true;
    }
  },
  methods: {
    updateCountDown() {
      const now = new Date().getTime();
      const distance = this.countDownTime + this.countDownDelay - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24)) < 0 ? 0 : Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)) < 0 ? 0 : Math.floor(distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
      const minutes = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60)) < 0 ? 0 : Math.floor(distance % (1000 * 60 * 60) / (1000 * 60));
      this.countDown = {
        days: days < 10 ? `0${days}` : `${days}`,
        hours: hours < 10 ? `0${hours}` : `${hours}`,
        minutes: minutes < 10 ? `0${minutes}` : `${minutes}`
      };
    },
    startCountDown() {
      this.updateCountDown();
      this.interval = window.setInterval(() => {
        this.updateCountDown();
      }, 1000);
    }
  },
  created() {
    this.startCountDown();
  },
  unmounted() {
    clearInterval(this.interval);
  }
});