import phase1LunarAllowlist from '@/resources/phase1-lunar-allowlist.json';
import phase2LunarAllowlist from '@/resources/phase2-lunar-allowlist.json';
import astralAllowlist from '@/resources/astral-allowlist.json';
import { AllowlistItem } from "@/utils/allowlists/types";

export function getAllowlistIndexedByWalletAddress(allowlist: AllowlistItem[]): Record<string, AllowlistItem> {
    const allowlistIndexedByWalletAddress: Record<string, AllowlistItem> = {};
    for (const allowlistItem of allowlist) {
        allowlistIndexedByWalletAddress[allowlistItem.address.toLowerCase()] = allowlistItem;
    }

    return allowlistIndexedByWalletAddress;
}

export function getPhase1LunarAllowlistIndexedByWalletAddress() {
    return getAllowlistIndexedByWalletAddress(phase1LunarAllowlist);
}

export function getPhase2LunarAllowlistIndexedByWalletAddress() {
    return getAllowlistIndexedByWalletAddress(phase2LunarAllowlist);
}

export function getAstralAllowlistIndexedByWalletAddress() {
    return getAllowlistIndexedByWalletAddress(astralAllowlist);
}
