import coinbaseWalletModule from '@web3-onboard/coinbase';
import injectedModule from '@web3-onboard/injected-wallets';
import ledgerModule from '@web3-onboard/ledger';
import mewWalletModule from '@web3-onboard/mew-wallet';
import portisModule from '@web3-onboard/portis';
import walletConnectModule from '@web3-onboard/walletconnect';
import { init, useOnboard } from '@web3-onboard/vue';
import { L1_CHAIN } from '@/chains';
import { ConnectOptions } from '@web3-onboard/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import KizunaIcon from '@/assets/icons/kizuna-k.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import KizunaLogo from '@/assets/icons/kizuna-logo.svg';

const coinbaseWallet = coinbaseWalletModule({ darkMode: true });
const injected = injectedModule();
const ledger = ledgerModule({chainId: 1, rpc: {1: L1_CHAIN.rpcUrl  ?? ''}});
const mewWallet = mewWalletModule()
const portis = portisModule({ apiKey: process.env.VUE_APP_WALLET_PORTIS_API_KEY || '' })
const walletConnect = walletConnectModule({ version: 2, projectId: '99a96161d17fdd8317a99bc9cb4366a3', connectFirstChainId: true });

const onboard = init({
    wallets: [coinbaseWallet, injected, ledger, mewWallet, portis, walletConnect],
    chains: [L1_CHAIN],
    accountCenter: {
        desktop: {
            enabled: true,
            minimal: true
        },
        mobile: {
            enabled: true,
            minimal: true

        }
    },
    appMetadata: {
        icon: KizunaIcon,
        logo: KizunaLogo,
        name: 'Kizuna',
        description: 'An anime-themed NFT project and community of dreamers.'
    }
});

export const { wallets, connectWallet: connect, connectedWallet, connectedChain, setChain } = useOnboard();

export const connectWallet = (options?: ConnectOptions) => connect(options);

export const rememberWallets = () => {
    const walletsSub = onboard.state.select('wallets')
    const { unsubscribe } = walletsSub.subscribe((wallets) => {
        const connectedWallets = wallets.map(({ label }) => label)
        window.localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets))
    })

    return unsubscribe;
}

export const connectToRememberedWallets = async () => {
    if (!window?.localStorage) {
        return;
    }

    const savedWallets = window.localStorage.getItem('connectedWallets');
    if (!savedWallets) {
        return;
    }

    const previouslyConnectedWallets = JSON.parse(savedWallets)
    if (previouslyConnectedWallets) {
        await onboard.connectWallet({
            autoSelect: { label: previouslyConnectedWallets[0], disableModals: true }
        })
    }
}