import { defineComponent } from 'vue';
import { inject } from '@vercel/analytics';
import { rememberWallets } from '@/utils/web3-onboard';
export default defineComponent({
  data() {
    return {
      walletsConnectUnsubscriber: () => {}
    };
  },
  created() {
    this.walletsConnectUnsubscriber = rememberWallets();
  },
  mounted() {
    inject();
  },
  unmounted() {
    this.walletsConnectUnsubscriber();
  }
});