import { defineComponent } from 'vue';
import PublicPanel from './PublicPanel.vue';
import Phase1LunarPanel from './Phase1LunarPanel.vue';
import Phase2LunarPanel from './Phase2LunarPanel.vue';
import AstralPanel from './AstralPanel.vue';
import SuccessComicPage from './SuccessComicPage.vue';
import { connectWallet, connectedWallet, connectedChain, setChain } from '@/utils/web3-onboard';
import { Kizuna__factory } from '@/contracts';
import { BigNumber, ethers } from 'ethers';
import { getPhase1LunarAllowlistIndexedByWalletAddress, getPhase2LunarAllowlistIndexedByWalletAddress, getAstralAllowlistIndexedByWalletAddress } from '@/utils/allowlists';
import { L1_CHAIN } from '@/chains';
import wait from '@/utils/wait';
export default defineComponent({
  name: 'MintPanels',
  components: {
    PublicPanel,
    Phase1LunarPanel,
    Phase2LunarPanel,
    AstralPanel,
    SuccessComicPage
  },
  data() {
    return {
      connectedWallet,
      connectedChain,
      kizunaContract: null,
      publicMintAmount: 1,
      phase1LunarMintAmount: 1,
      phase2LunarMintAmount: 1,
      astralMintAmount: 1,
      publicMintedAmount: BigNumber.from(0),
      lunarMintedAmount: BigNumber.from(0),
      astralMintedAmount: BigNumber.from(0),
      isPendingApproval: false,
      isMinting: false,
      isSuccess: false,
      hasFailed: false,
      errorMessage: '',
      publicMintLimit: BigNumber.from(0),
      isPublicMintPaused: true,
      isPhase1LunarMintPaused: true,
      isPhase2LunarMintPaused: true,
      isAstralMintPaused: true,
      publicPrice: BigNumber.from(0),
      lunarPrice: BigNumber.from(0),
      astralSupply: BigNumber.from(0),
      totalSupply: BigNumber.from(0),
      maxSupply: BigNumber.from(0),
      phase1LunarAllowlistIndexedByWalletAddress: getPhase1LunarAllowlistIndexedByWalletAddress(),
      phase2LunarAllowlistIndexedByWalletAddress: getPhase2LunarAllowlistIndexedByWalletAddress(),
      astralAllowlistIndexedByWalletAddress: getAstralAllowlistIndexedByWalletAddress(),
      interval: 0
    };
  },
  props: {
    countDownTime: {
      type: Number,
      default: 0
    },
    canFetchData: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isWalletConnected() {
      if (!this.connectedWallet) {
        return false;
      }
      return true;
    },
    isCorrectChain() {
      if (!this.connectedWallet || this.connectedChain?.id !== L1_CHAIN.id) {
        return false;
      }
      return true;
    },
    walletAddress() {
      if (!this.connectedWallet?.accounts[0]?.address) {
        return '';
      }
      return this.connectedWallet?.accounts[0].address;
    },
    walletPublicSlotsRemaining() {
      if (this.publicMintedAmount.gte(this.publicMintLimit)) {
        return 0;
      }
      return +this.formatUnits(this.publicMintLimit.sub(this.publicMintedAmount), 0);
    },
    walletPublicTotalSlots() {
      if (!this.publicMintLimit) {
        return 0;
      }
      return +this.formatUnits(this.publicMintLimit, 0);
    },
    walletPhase1LunarTotalSlots() {
      if (!this.phase1LunarAllowlistIndexedByWalletAddress[this.walletAddress]?.count) {
        return 0;
      }
      return +this.phase1LunarAllowlistIndexedByWalletAddress[this.walletAddress].count;
    },
    walletPhase1LunarSlotsRemaining() {
      if (!this.phase1LunarAllowlistIndexedByWalletAddress[this.walletAddress]?.count) {
        return 0;
      }
      return +this.phase1LunarAllowlistIndexedByWalletAddress[this.walletAddress].count - +this.formatUnits(this.lunarMintedAmount, 0);
    },
    walletPhase2LunarTotalSlots() {
      if (!this.phase2LunarAllowlistIndexedByWalletAddress[this.walletAddress]?.count) {
        return 0;
      }
      return +this.phase2LunarAllowlistIndexedByWalletAddress[this.walletAddress].count;
    },
    walletPhase2LunarSlotsRemaining() {
      if (!this.phase2LunarAllowlistIndexedByWalletAddress[this.walletAddress]?.count) {
        return 0;
      }
      return +this.phase2LunarAllowlistIndexedByWalletAddress[this.walletAddress].count - +this.formatUnits(this.lunarMintedAmount, 0);
    },
    walletAstralTotalSlots() {
      if (!this.astralAllowlistIndexedByWalletAddress[this.walletAddress]?.count) {
        return 0;
      }
      return +this.astralAllowlistIndexedByWalletAddress[this.walletAddress].count;
    },
    walletAstralSlotsRemaining() {
      if (!this.astralAllowlistIndexedByWalletAddress[this.walletAddress]?.count) {
        return 0;
      }
      return +this.astralAllowlistIndexedByWalletAddress[this.walletAddress].count - +this.formatUnits(this.astralMintedAmount, 0);
    },
    currentActivePhase() {
      if (!this.isAstralMintPaused) {
        return 'ASTRAL';
      }
      if (!this.isPublicMintPaused) {
        return 'PUBLIC';
      }
      if (!this.isPhase1LunarMintPaused) {
        return 'P1LUNAR';
      }
      if (!this.isPhase2LunarMintPaused) {
        return 'P2LUNAR';
      }
      return '';
    }
  },
  methods: {
    connectWallet,
    truncateWalletAddress(walletAddress) {
      if (!walletAddress) {
        return '';
      }
      return walletAddress.slice(0, 4) + '...' + walletAddress.slice(-4);
    },
    formatUnits(bigNumber, digits = 18) {
      if (!bigNumber) {
        return '';
      }
      return ethers.utils.formatUnits(bigNumber, digits);
    },
    async fetchContractStaticData() {
      if (!this.kizunaContract || !this.canFetchData) {
        return;
      }
      try {
        this.publicMintLimit = await this.kizunaContract.mintLimitPerWallet();
        this.lunarPrice = await this.kizunaContract.whitelistPrice();
        this.publicPrice = await this.kizunaContract.publicPrice();
        this.maxSupply = await this.kizunaContract.maxSupply();
      } catch (e) {
        console.error(e);
      }
    },
    async fetchContractData() {
      if (!this.kizunaContract || !this.canFetchData) {
        return;
      }
      try {
        this.isPhase1LunarMintPaused = await this.kizunaContract.isWhitelistMintPhase1Paused();
        this.isPhase2LunarMintPaused = await this.kizunaContract.isWhitelistMintPhase2Paused();
        this.isAstralMintPaused = await this.kizunaContract.isFreeMintPaused();
        this.isPublicMintPaused = await this.kizunaContract.isPublicMintPaused();
        this.totalSupply = await this.kizunaContract.totalSupply();
        this.astralSupply = await this.kizunaContract.freeMintSupply();
        if (!this.walletAddress) {
          return;
        }
        this.publicMintedAmount = await this.kizunaContract.publicMintAddressesMintedAmount(this.walletAddress);
        this.lunarMintedAmount = await this.kizunaContract.whitelistAddressesMintedAmount(this.walletAddress);
        this.astralMintedAmount = await this.kizunaContract.freeAddressesMintedAmount(this.walletAddress);
      } catch (e) {
        console.error(e);
      }
    },
    async handlePublicMint() {
      if (!this.isWalletConnected || !this.kizunaContract || this.publicMintAmount < 1) {
        return;
      }
      try {
        if (this.totalSupply.gt(this.maxSupply.sub(this.astralSupply).sub(this.publicMintAmount))) {
          throw new Error(`There aren't enough NFTs left in this phase.`);
        }
        this.isPendingApproval = true;
        if (!this.isCorrectChain) {
          await this.changeChain();
          await wait(2000);
        }
        if (!this.isCorrectChain) {
          throw new Error(`Please connect to the ${L1_CHAIN.label} network.`);
        }
        const transaction = await this.kizunaContract.mint(this.publicMintAmount, {
          value: this.publicPrice.mul(this.publicMintAmount)
        });
        this.isPendingApproval = false;
        this.isMinting = true;
        await transaction.wait();
        this.isMinting = false;
        this.isSuccess = true;
        this.publicMintAmount = 1;
      } catch (e) {
        this.isPendingApproval = false;
        this.isMinting = false;
        this.hasFailed = true;
        if (e instanceof Error) {
          this.errorMessage = e.message;
        }
        console.error(e);
      }
      await this.fetchContractData();
    },
    async handlePhase1LunarMint() {
      if (!this.isWalletConnected || !this.kizunaContract || this.phase1LunarMintAmount < 1 || !this.walletPhase1LunarSlotsRemaining) {
        return;
      }
      try {
        if (this.totalSupply.gt(this.maxSupply.sub(this.astralSupply).sub(this.phase1LunarMintAmount))) {
          throw new Error(`There aren't enough NFTs left in this phase.`);
        }
        this.isPendingApproval = true;
        if (!this.isCorrectChain) {
          await this.changeChain();
          await wait(2000);
        }
        if (!this.isCorrectChain) {
          throw new Error(`Please connect to the ${L1_CHAIN.label} network.`);
        }
        const transaction = await this.kizunaContract.whitelistMintPhase1(this.phase1LunarMintAmount, this.phase1LunarAllowlistIndexedByWalletAddress[this.walletAddress].count, this.phase1LunarAllowlistIndexedByWalletAddress[this.walletAddress].merkleProof, {
          value: this.lunarPrice.mul(this.phase1LunarMintAmount)
        });
        this.isPendingApproval = false;
        this.isMinting = true;
        await transaction.wait();
        this.isMinting = false;
        this.isSuccess = true;
        this.phase1LunarMintAmount = 1;
      } catch (e) {
        this.isPendingApproval = false;
        this.isMinting = false;
        this.hasFailed = true;
        if (e instanceof Error) {
          this.errorMessage = e.message;
        }
        console.error(e);
      }
      await this.fetchContractData();
    },
    async handlePhase2LunarMint() {
      if (!this.isWalletConnected || !this.kizunaContract || this.phase2LunarMintAmount < 1 || !this.walletPhase2LunarSlotsRemaining) {
        return;
      }
      try {
        if (this.totalSupply.gt(this.maxSupply.sub(this.astralSupply).sub(this.phase2LunarMintAmount))) {
          throw new Error(`There aren't enough NFTs left in this phase.`);
        }
        this.isPendingApproval = true;
        if (!this.isCorrectChain) {
          await this.changeChain();
          await wait(2000);
        }
        if (!this.isCorrectChain) {
          throw new Error(`Please connect to the ${L1_CHAIN.label} network.`);
        }
        const transaction = await this.kizunaContract.whitelistMintPhase2(this.phase2LunarMintAmount, this.phase2LunarAllowlistIndexedByWalletAddress[this.walletAddress].count, this.phase2LunarAllowlistIndexedByWalletAddress[this.walletAddress].merkleProof, {
          value: this.lunarPrice.mul(this.phase2LunarMintAmount)
        });
        this.isPendingApproval = false;
        this.isMinting = true;
        await transaction.wait();
        this.isMinting = false;
        this.isSuccess = true;
        this.phase2LunarMintAmount = 1;
      } catch (e) {
        this.isPendingApproval = false;
        this.isMinting = false;
        this.hasFailed = true;
        if (e instanceof Error) {
          this.errorMessage = e.message;
        }
        console.error(e);
      }
      await this.fetchContractData();
    },
    async handleAstralMint() {
      if (!this.isWalletConnected || !this.kizunaContract || this.astralMintAmount < 1 || !this.walletAstralSlotsRemaining) {
        return;
      }
      try {
        if (this.totalSupply.gt(this.maxSupply.sub(this.astralMintAmount))) {
          throw new Error(`There aren't enough NFTs left in this phase.`);
        }
        this.isPendingApproval = true;
        if (!this.isCorrectChain) {
          await this.changeChain();
          await wait(2000);
        }
        if (!this.isCorrectChain) {
          throw new Error(`Please connect to the ${L1_CHAIN.label} network.`);
        }
        const transaction = await this.kizunaContract.freeMint(this.astralMintAmount, this.astralAllowlistIndexedByWalletAddress[this.walletAddress].count, this.astralAllowlistIndexedByWalletAddress[this.walletAddress].merkleProof);
        this.isPendingApproval = false;
        this.isMinting = true;
        await transaction.wait();
        this.isMinting = false;
        this.isSuccess = true;
        this.astralMintAmount = 1;
      } catch (e) {
        this.isPendingApproval = false;
        this.isMinting = false;
        this.hasFailed = true;
        if (e instanceof Error) {
          this.errorMessage = e.message;
        }
        console.error(e);
      }
      await this.fetchContractData();
    },
    async changeChain() {
      if (!this.isWalletConnected || !this.connectedWallet?.label || this.isCorrectChain) {
        return;
      }
      return setChain({
        chainId: L1_CHAIN.id,
        wallet: this.connectedWallet.label
      });
    }
  },
  created() {
    const ethersProvider = ethers.providers.getDefaultProvider(L1_CHAIN.rpcUrl);
    this.kizunaContract = Kizuna__factory.connect(process.env.VUE_APP_CONTRACT_ADDRESS_KIZUNA || '', ethersProvider);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  watch: {
    connectedWallet() {
      if (!this.connectedWallet?.provider) {
        const ethersProvider = ethers.providers.getDefaultProvider(L1_CHAIN.rpcUrl);
        this.kizunaContract = Kizuna__factory.connect(process.env.VUE_APP_CONTRACT_ADDRESS_KIZUNA || '', ethersProvider);
        this.fetchContractData();
        return;
      }
      if (!this.isCorrectChain) {
        return;
      }
      const ethersProvider = new ethers.providers.Web3Provider(this.connectedWallet.provider);
      this.kizunaContract = Kizuna__factory.connect(process.env.VUE_APP_CONTRACT_ADDRESS_KIZUNA || '', ethersProvider.getSigner());
      this.fetchContractData();
    },
    async canFetchData() {
      if (!this.canFetchData) {
        return;
      }
      await this.fetchContractStaticData();
      await this.fetchContractData();
      this.interval = window.setInterval(() => {
        this.fetchContractData();
      }, 20000);
    }
  }
});