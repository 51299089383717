import { defineComponent } from 'vue';
export default defineComponent({
  name: 'ComicPage',
  components: {},
  data() {
    return {
      displayThinkAgainComic: false
    };
  },
  props: {
    close: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    scrollToTopOfWrapper() {
      const element = document.getElementById('comic-container-wrapper');
      if (!element) {
        return;
      }
      element.scrollTop = 0;
    }
  }
});