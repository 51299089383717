import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Phase1LunarPanel',
  props: {
    value: {
      type: Number,
      default: 1
    },
    isWalletConnected: {
      type: Boolean,
      default: false
    },
    connectWallet: {
      type: Function,
      default: () => {}
    },
    mint: {
      type: Function,
      default: () => {}
    },
    mintPrice: {
      type: String,
      default: ''
    },
    maxMint: {
      type: Number,
      default: 0
    },
    totalSlots: {
      type: Number,
      default: 0
    },
    isPhasePaused: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    cost() {
      return (+this.mintPrice * this.value).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 5
      });
    }
  }
});